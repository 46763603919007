.boxCroppedSample {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c1c1c;
    flex-direction: column;
    margin: 5px 0!important;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.7);
    width: 100%;
    min-width: 700px;
}

.ReactCrop__image{
    min-width: 300px;
    max-width: 800px;
}

.ReactCrop__crop-selection{
    border-image-source: none;
    border: 1px solid #ffc109;
    border-radius: 3px;
}

.ReactCrop{
   object-fit: contain;
    margin: 0 auto 0 auto;
    background-color: #1c1c1c;
    border-radius: 3px;}

.tagInsideDiv{
    display: flex;
    flex-wrap: wrap;

}
.tagInsideDiv div{
    /*padding: 0 5px;*/
    background-color: rgba(0,0,0,0.7);
    border-radius: 2px;

    padding: 0 5px;
    margin: 0 1px 1px  0;
    font-family: "Helvetica Neue";


}