.containerAllVideosPage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.allVideosWrapper {
    position: relative;
    width: 80%;
    margin: auto;

}

.allVideos {
    width: 90%;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-shadow: 0 0 5px black;
    /*background-color: rgba(0, 0, 0, 0.1);*/
    border-radius: 5px;
    overflow: auto;

    padding: 30px;
    margin: 20px auto 0 auto;

}
.allVideos::-webkit-scrollbar{
    display: none;
}


.backArrowDiv {
    position: absolute;
    left: 7.5%;
    bottom: -0.5%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    transition: 100ms;
    color: #323232;
}


.backArrowDiv :hover{
    color: crimson;
    transition: 100ms;
}

.backArrowDiv svg{
    font-size: 80px;
}
.backArrowDiv svg path{
    fill: crimson;
}
.goToVideosDiv{
    position: absolute;
    bottom: 25px;
    left: 150px;
    width: 100px;
}

.goToVideos{
    width: 80px
}
.onVideo {
    width: inherit;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.9));
    transition: 300ms;

}

.goFolderImage{
    position: absolute;
    right: 3%;
    color: #323232;
    bottom: -10px;
    transform: translate(-50%, -50%);
    font-size: 70px;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.9));
    transition: 200ms;

}

.goFolderImage:hover{
    color: crimson;
    transition: 200ms;
}


.onVideoDiv {
    position: absolute;
    left: 13%;
    top: 12%;
    transform: translate(-50%, -50%);
    width: 56px;
}


.onCollection{
    position: absolute;
    top: -85px;
    right: 5px;
    width: 120px;
}

.bottomDivSlidesVideo{
    height: 83px;
    width: 90%;
    background-color: #212121;
    position: absolute;
    z-index: 10000;
    left: 50%;
    top: 77%;
    transform: translate(-50%, -50%);
    display: flex;
    color: crimson;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
    letter-spacing: 0.05em;
    font-family: "Helvetica Neue";
    font-size: 50px;
}

.dragPicDiv{
    margin: 0 -300px;
}
.dragPicDiv img{
    width: 70px;
}

.step_1_Video_ImageDiv {
    position: relative;
    bottom: 15px;
}
.step_1_Video_ImageDiv img{
    width: 350px;
    height: 90px;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.9));
}
.marginAdjustVideo{
    margin-bottom: 5px!important;
}

.uploadHint{
    position: absolute;
    transform: translate(-50%, -50%);
    right: -52px;
    top: 16%;
    width: 100px!important;
    height: 87px!important;
}

.giphy-embed{
    max-width: 110px;
    max-height: 110px;
    border-radius: 10px;
    box-shadow: 0 0 2px 2px black;
    margin-left: 10px;
    position: relative;
    top:7px
}

.gifDivText{
    display: flex;
}

.textDiv{
    font-family: "Helvetica Neue";
    font-weight: 400;
}
.fa-long-arrow-alt-right{
    font-size: 30px;
    margin-left: 10px;
    position: absolute;
    left: 43%;
}
.arrow2Fragmentation{
    bottom: 65px;
    left: 37%;

}

.extractTutorialDiv{
    margin-top: 15px;
    position: absolute;
    left: 10%;
    bottom: 15%;
}
.extractTutorialDiv img{
    width: 100px;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.9));
}

.schemaFragmentation{
    position: relative;
    top: 5px;
    left: 23px;
}
.schemaFragmentation img{
    width: 200px;
    height: 150px;

}
.videoCover{
    position: absolute;
    right: 25px;
    top: 40px;
    height: 100px;
}

.goToFolders img{
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
    margin-top: 20px;
}