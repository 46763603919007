
.swiperWrapper {
    border-radius: 5px;
    margin: 10px auto;
    overflow: hidden;
    height: 740px;
    position: relative;
    background-color: #212121;
}

.headerSlideShow {
    position: relative;
    width: 900px;
    height: 60px;
    z-index: 999;
    overflow: hidden;
    display: flex;
    align-items: center;

}

.titleSlide {
    font-size: 30px;
    color: crimson;
    margin-left: 1em;
    -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 1));
}

.totalImagesTText {
    margin-left: 15px;
    position: relative;
    color: #a9a9a9;
    top: 5px;

}

.headerIcons {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));

}

.headerIcons svg {
    font-size: 35px;
    margin: 0 7px;
}

.headerIcons svg path {
    fill: crimson;
}


.swiper-container {
    max-width: 900px;
    max-height: 550px;
    border: 1px solid #333333;
    margin: auto !important;

}

.swiper-slide {
    text-align: center;
    background: #333333;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide svg {
    font-size: 400px;

}

.swiper-slide svg path {
    fill: crimson;
}


.append-buttons {
    text-align: center;
    padding: 15px 0 5px 0;
}

.append-buttons a {
    display: inline-block;
    border: 1px solid crimson;
    color: crimson;
    text-decoration: none;
    padding: 4px 20px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
    box-shadow: 1px 1px 2px black;

}

.imagesSwiper {
    position: relative;
    max-width: 900px;
    max-height: 600px;

}

.swiper-button-prev, .swiper-button-next {
    color: crimson !important;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: crimson !important;
}


.swiper-scrollbar {
    /*height: 10px;*/
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.swiper-scrollbar-drag {
    background-color: crimson !important;
    height: 7px !important;
}


a:link {
    text-decoration: none!important;
}



.buttonsArrowsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 30px 0 auto;

}

.buttonsArrowDiv {
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
    margin-top: 10px;

}

.buttonsArrowDiv div {
    margin: 10px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: crimson;
    border-radius: 10px;
}

.buttonsArrowDiv div svg path {
    fill: black;
}

.arrowsInfo {
    color: crimson;
    text-align: center;
    font-family: "Helvetica Neue";
    margin: 15px auto 0 auto;
    font-weight: 300;
    letter-spacing: 0.05em;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.7);
    border-radius: 5px;

    padding: 2px 5px;
}

.noCollections {
    width: inherit;
    height: inherit;
    border: 1px solid crimson;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.numberSlide {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 9%;
    right: 15px;
    z-index: 1000;
    color: #ffc109;
    background-color: #212121;
    border-radius: 10px;
    padding: 3px 15px;
    font-size: 20px;
}