.react-responsive-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.video_wrapper{
    width: 100%    !important;
    height: 655px   !important;
}

.video_wrapper iframe{
    outline-color: crimson !important;
    height: 655px   !important;

}

.react-responsive-modal-modal{
    background-color: #242222 !important;
    max-width: 1200px!important;
    width: 100%;
    height: auto;
    margin: auto;
}
.react-responsive-modal-modal h3{
    color: crimson;

}

.react-responsive-modal-closeButton{
    border: none;
    outline: none!important;
}
.react-responsive-modal-closeButton:hover{
  box-shadow: 0 0 3px 2px rgba(0,0,0,0.7);
    border-radius: 7px;
}
svg path{
    fill: crimson;
}

@media only screen and (max-width: 1024px) {
    .video_wrapper, .video_wrapper iframe  {
        height: 500px !important;
    }
}

@media only screen and (max-width: 800px) {
    .video_wrapper .video_wrapper iframe {
        height: 400px !important;
    }
}

@media only screen and (max-width: 650px) {
    .video_wrapper, .video_wrapper iframe {
        height: 330px !important;
    }
}

@media only screen and (max-width: 550px) {
    .video_wrapper,  .video_wrapper iframe  {
        height: 300px !important;
    }
}

@media only screen and (max-width: 450px) {
    .react-responsive-modal-modal h3{
        font-size: 25px;
    }
    .video_wrapper,  .video_wrapper iframe {
        height: 250px   !important;
    }
}

@media only screen and (max-width: 400px) {
    .video_wrapper, .video_wrapper iframe  {
        height: 200px   !important;
    }
}