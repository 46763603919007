.footer-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: crimson;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: none;
  background-color:  #c6c6c6 !important;

}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;

}

.footer-link-items > h2 {
  color: crimson;
  /*color: #ccff00;*/
  font-size: 28px;
}

.footer-link-items a {
  /*color: #ccff00;*/
  color: #c6c6c6;
  text-decoration: none;
  margin-left: 5px;
}

.footer-link-items a:hover {
  color: #ccff00;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #3b3a3a;

}

/* Social Icons */

.social-icon-link {
  color: crimson;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin-bottom: 16px;

}
.social-icons:hover {
  color: #ccff00;
}

.social-logo {
  color: #dc143c;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 3rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.social-logo:hover{
  color: #ccff00;
  text-decoration: none;
}
.footer-logo{
  font-size: 2.8rem;
}


.website-rights {
  position: relative;
  color: crimson;
  font-size: 15px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-input {
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
  }
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 384px) {
  .footer-container{
    width: 110%;
  }
}
