
.clause {
    border-radius: 50px ! important;
    padding: 0 !important;
    height: 140px;
}



.displayNone {
    display: None;
}



.button_upload {
    position: absolute;
    left: 76%;
    top: 14.5%;
    width: 50px;
}

.multi-preview {
    display: flex;
    border-radius: 0 0 5px 5px;
    width: 100%;
    margin: auto !important;
}

.innerPreviewWrapper {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    margin: auto;
}

.innerPreviewWrapper::-webkit-scrollbar {
    display: none;
}

.innerVideo {
    margin: 5px;
    height: fit-content;
    display: flex;
    position: relative;
}

.innerVideo video {
    border-radius: 5px;
    margin: auto;
    width: 150px;
    transition: ease-out 100ms;
    box-shadow: 0 0 2px 1px black;

}

.innerVideo video:hover {
    box-shadow: 0 0 5px 3px black;
    transition: ease-out 100ms;
}

.videoIconPrev {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    background-color: #212121;
    border-radius: 5px;
}

.videoIconPrev path {
    fill: crimson;
}

.noVideosDiv {
    margin: auto;
    height: 275px;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.existing_namesDiv {
    position: absolute;
    color: crimson;
    z-index: 20000;
    left: 50%;
    display: flex;
    top: 92%;
    transform: translate(-50%, -50%);
}

.existing_namesDiv div {
    color: #a9a9a9;
    margin: 0 3px;
    font-family: "Helvetica Neue";
    font-weight: 300;
}


.chooseFileDiv {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 25%;
    left: 92%;
    filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.9));
    transition: 200ms;
}
.chooseFileDiv:hover{
    top: 24%;
    filter: drop-shadow(1px 5px 3px rgba(0, 0, 0, 1));
}

.chooseFileDiv img {
    width: 75px;
    height: 75px;

}


.previewContainer{
    width: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top:22%;
    box-shadow: 0 0 4px 2px crimson;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.2        );
}