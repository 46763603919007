.cards_item {
  display: flex;
  margin:1rem;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  background-color: rgba(41,41,41,0.8);
}

.cards_item_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  /*border: 1px solid rgba(220, 20, 60, 0.4);*/
}

.cards_item_link:link{
  text-decoration: none;

}


.cards_item_link:hover{
  box-shadow: 0 0 5px #ffc109;
  border: 1px solid #ffc109;
  /*box-shadow: 0 0 5px crimson;*/
  /*border: 1px solid crimson;*/
}

.cards_item_pic-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.labelCard{
  position: relative;
  bottom: 45px;
  margin-left: 10px;
  padding: 6px 8px;

  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
  text-decoration: none;

}


.cards_item_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.77));
}

.cards_item_img:hover {
  transform: scale(1.1);
  -webkit-filter: none;

}

.cards_item_info {
  width: 100%;
  margin: auto;
  padding: 0 10px 10px;
}

.cards_item_text {
  color: #fff!important;
  font-size: 18px;
}

@media only screen and (max-width: 384px) {
  .cards {
    width: 110%;
  }
}

@media only screen and (min-width: 1200px) {
  .content_blog_container {
    width: 84%;
  }
}


@media only screen and (max-width: 1024px) {
  .cards_items{
    display: flex;
    flex-direction: column;
  }
  .cards_item {
    margin-bottom: 2rem;
  }

}


