.collectionCardDiv{
    width: 700px;
    height: 350px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,1);
    position: relative;
    margin: 20px auto;
    border-radius: 5px;
    transition: 200ms ease-in;
    align-items: center;
display: flex;
background-color: #1c1c1c;
    opacity: 0.8;
}

.collectionCardDiv:hover{
    box-shadow: 0 0 3px 3px rgba(0,0,0,0.7);
    transition: 200ms ease-out;
    background-color: #1c1c1c;
    opacity: 1;

}

.imageCoverCollection{
    width:400px;
    height: 280px;
    border-radius: 5px;
    margin-left: 40px;
}

.titleFolderCardCollection{
    position: absolute;
    color: white;
    font-family: "Helvetica Neue";
    font-size: 30px;
    background-color: crimson;
    width: 400px;
    height: 50px;
    margin-left: 40px;
    bottom: 34px;
    padding-left: 20px;
    border-radius: 0 0 5px 5px;
    letter-spacing: 0.1em;
    font-weight: 300;
}

.numberOfCollectionDiv{
    color: #a9a9a9;
    font-size: 40px;
    position: absolute;
    top: 20px;
    right: 30px;
    font-family: "Helvetica Neue";
    font-weight: 300;

}

.totalImagesCollectionDivNumber{

}