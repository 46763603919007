.particlesBackground {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

#tsparticles {
    width: 100%;
    height: 100%;
}