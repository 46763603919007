.currentTagBuildingContainerBottomFather {
    width: 100%;
    max-width: 588px;
    background-color: #212121;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    /*margin: 0 0 5px 4px;*/
    /* margin: auto; */
    grid-area: categories;
}

.currentTagBuildingContainerBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 230px;
    border-radius: 5px;
    overflow: auto;
}

.searchBarDiv {
    display: flex;
    justify-content: center;
}

.inputSearch {
    width: 98%;
    height: 35px;
    border-radius: 50px;
    border: none;
    margin-top: 10px;
    background-color: #252525;
    font-family: "Helvetica Neue";
    color: #a9a9a9;
    outline: none;
    box-shadow: 0 0 2px #a9a9a9;
    padding-left: 15px;
}

.inputSearch:focus, .inputSearch:not(:focus) {
    padding-left: 15px;

}

.existingCategoryDiv {
    box-shadow: 0 2px 4px 2px black;
    background-color: #1c1c1c;
    width: 240px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    /* margin: 15px 10px 0 10px; */
    border-radius: 5px;
    height: 200px;
}

.accordionNewWrapper {
    grid-area: addNew;
    background-color: #212121;
    border-radius: 5px;
    max-width: 600px;
}

.allCategoriesCurrentCrop {
    color: #a9a9a9;
    display: flex;
    margin: 5px 10px 5px 10px;
}

.leftCategoriesWrapper {
    height: 200px;
}

/* add new category*/

.bodyCategoryCurrentCrop {
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    color: #a9a9a9;
    font-family: "Helvetica Neue";
    font-style: italic;
    letter-spacing: 0.05em;
}

.displayFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.addNewCategoryRoot {
    /* min-width: 140px; */
    display: flex;
    justify-content: center;
    /* margin: 3px 35px 3px 40px; */
    font-style: normal;
    /*box-shadow: 0 0 1px 1px #1e9dff;*/
    color: #ffc109;
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.08em;
    align-items: center;
    padding: 5px 0 0 0;
    border-radius: 3px;
}

.addNewCategory {
    display: flex;
    padding: 10px 0 15px 0;
    align-items: center;
    justify-content: space-evenly;
}

.wrapperInputCategory {
    align-items: flex-start;
    display: flex;
    margin: 6px 0 6px 10px;
    box-shadow: 0 0 0 1px #ffc109;
    border-radius: 3px;
}

.wrapperLabelCategory {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-size: 12px;
    display: flex;
    font-style: normal;
    align-items: center;
    margin: 5px 5px 0 5px;
    width: 40px;
    color: #a9a9a9;
    justify-content: center;
}

.marginAdd {
    width: fit-content;
    font-size: 11px;
    margin: auto;
}

.categoryNameCurrentCrop {
    color: #ffc109;
    /*border: 1px solid white;*/
    box-shadow: inset 0 0 4px 1px black;
    padding: 0 7px 1px 7px;
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 3px 0 0 3px;
    position: relative;
    font-weight: 400;
    font-family: "Helvetica Neue";
    background-color: #333333;
}

.addTagInput {
    border: none;
    height: 28px;
    width: 150px;
    border-radius: 3px;
    font-family: "Helvetica Neue";
    background-color: #1c1c1c;
    font-size: 12px;
    outline: none;
    font-weight: 400;
}

.adjustButtonAdd {
    height: fit-content !important;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.7) !important;
}

.addTagInput:focus {
    color: #a9a9a9;
    font-family: "Helvetica Neue";
}

.addTagInput:not(:focus) {
    color: #ffc109;
    font-family: "Helvetica Neue";
}

.addTagInput::placeholder {
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0.08em;
}

.addTagButton {
    width: 25px;
    height: 66px;
    border: none;
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-size: 13px;
    outline: none !important;
    border-radius: 50px;
    box-shadow: 0 2px 2px 1px black;
    background-color: #ffc109;
    margin: 0 10px 0 0;

}

.addTagButton:hover {
    box-shadow: 0 0 4px 2px black;
}

.addTagButton svg {
    position: relative;
    bottom: 1px;

}

.addTagButton svg path {
    position: relative;
    bottom: 1px;
    fill: #212121;

}

.WrapperTagAdjustCategories {
    box-shadow: 0 0 1px 1px #a9a9a9 !important;
    height: fit-content;
}

.WrapperTagAdjustCategories div {
    color: #a9a9a9;
    font-weight: 400;
}

.WrapperTagAdjustCategories svg path {
    fill: #a9a9a9;
}

.WrapperTagAdjustCategories:hover {
    box-shadow: 0 0 1px 1px #7BDCB5 !important;
}

.WrapperTagAdjustCategories div:hover {
    color: #7BDCB5;
}