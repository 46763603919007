.tagDivWrapper{
  display: inline-flex;
  height: 1.2em;
  box-shadow: 0 0 1px 1px #ccff00;
  border-radius: 50px;


  margin: 2px 3px;

}
.textDivTag{
  padding: 0 2px 1px 5px;
  align-items: center;
  font-weight: 300;
  position: relative;
  font-size: 13px;
  letter-spacing: 0.01em;
  font-family: "Helvetica Neue";
  font-style: italic;
  color: #ccff00;
  cursor: pointer;
}

.deleteTagIcon{
  font-size: 20px;
  cursor: pointer;
}

.deleteTagIcon path:hover{
  fill: crimson;
}