.VideoCard {

    margin: 5px 7px;
    border-radius: 6px;
    box-shadow: 0 0 3px 1px #000000;
    -webkit-filter: drop-shadow(0 0 0 rgba(41, 41, 41, 0.77));
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: 400ms;
    position: relative;
    bottom: 0;
}

.VideoCard:hover {
    box-shadow: 0 2px 5px 4px rgba(0, 0, 0, 0.9);
    -webkit-filter: drop-shadow(0 0 0 rgba(41, 41, 41, 0.77));
    bottom: 10px;
    transition:400ms;
}

.headerVideoCard {
    display: flex;
    background-color: #1c1c1c;
    border-radius: 5px 5px 0 0;
}


.leftVideoCard {
    border-radius: 3px 3px 0 0;
    width: 490px;
    height: 275px;
}

.rightVideoCard {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #1c1c1c;
    border-radius: 0 0 5px 5px;
}

.ads {
    color: crimson;
    font-size: 20px;
    text-align: center;
    margin: 5px 10px 5px auto;
}

.adsIcon {
    font-size: 19px;
}


.ChooseDiv {
    color: #a9a9a9;
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-size: 15px;
    margin: 10px;
    letter-spacing: 0.05em;
}

.ChooseDiv svg {
    font-size: 20px;
}

.ChooseDiv svg path {
    fill: #ffc109;
}

.titleVideoCard {
    display: flex;
    align-items: center;
    color: #a9a9a9;
    font-family: "Helvetica Neue";
    font-weight: 400;
    letter-spacing: 0.05em;
    margin-left: 10px;
}

.titleVideoCard svg {
    font-size: 20px;
    margin-left: 10px;
}

.titleVideoCard svg path {
    fill: crimson;
}


.reactPlayer {
    width: inherit !important;
    height: inherit !important;
}

.reactPlayer video {
    border-radius: 5px 0 0 5px !important;


}

.css-13cua4c {
    display: inline-block !important;
    position: relative !important;
    left: 10px;
    top: 5px;

}

.loadingClassActive {
    font-size: 10px !important;
    margin: auto;
}

.loadingClass {
    margin: auto;
    font-family: "Helvetica Neue";
    font-size: 10px;
}

.sliderDiv {
    display: flex;
    align-items: flex-end;
}

.slider_videos {
    width: 80% !important;
    margin: auto;
}

.rc-slider-rail {
    background-color: #212121 !important;
}

.rc-slider-track {
    background-color: #4e4e4e !important;
    box-shadow: 0 0 4px 3px black;
}

.rc-slider-handle {
    border: 2px solid #5f5f5f !important;
    background-color: crimson !important;
}

.ff_parent {
    display: flex;
    flex-direction: row;
    margin: 10px;
    justify-content: space-between;
    align-items: flex-end;
}

.delete {
    position: relative;
    margin: 0 7% 0 auto;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Helvetica Neue";
    font-weight: 300;
    color: #a9a9a9;
    letter-spacing: 0.05em;

}

.checkbox {
    position: relative;
    margin-left: 7px !important;
    padding: 0 !important;
}

.checkbox path {
    fill: #ff4163 !important;

}

.fps {
    font-size: 12px;
    font-family: "Helvetica Neue";
    font-weight: 300;
    color: #a9a9a9;
    letter-spacing: 0.05em;
}

.frames {
    margin-left: 10px;
    font-size: 12px;
    font-family: "Helvetica Neue";
    font-weight: 300;
    color: #a9a9a9;
    letter-spacing: 0.05em;
}

.videoCardButtonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 10px 0 0;
}

.videoCardButtonsDiv Button {
    background-color: #333333;
    width: 90px !important;
    height: 25px !important;
    font-family: "Helvetica Neue";
    padding: 0 !important;
    letter-spacing: 0.05em;

    font-size: 10px;


}

.buttonFragment {
    border-radius: 5px 20px 5px 20px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}

.buttonDelete {
    border-radius: 5px 20px 5px 20px !important;
    color: crimson;

}

.frameRateColor{
    color: #ffc109;
}

.descriptionSegmentation{
    font-weight: 400;
    font-family: "Helvetica Neue";
}

