.totFoldersCropDiv {
    width: 800px;
    position: absolute;
    display: flex;
    left: 52.5%;
    font-size: 45px;
    top: 11%;
    font-weight: 300;
    color: #a9a9a9;
    letter-spacing: 0.06em;
    font-family: "Helvetica Neue";
    transform: translate(-50%, -50%);
}

.numberLengthCrop {
    color: crimson;
    background-color: #212121;
    min-width: 60px;
    text-align: center;
    padding: 0;
    margin-left: 8px;
    height: fit-content;
    font-size: 40px;
    border-radius: 10px;
    position: relative;
    bottom: 3px;

}

.foldersContainer2 {
    background-color: unset;
    width: 800px;
    height: 800px !important;
    position: absolute;
    left: 50%;
    top: 57.3%;
    overflow: auto;
    box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 1);
    transform: translate(-50%, -50%);
}


