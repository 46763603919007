.mainCropContainer {
    width: 100%;
    height: 100%;
    z-index: 10000;
    grid-gap: 10px;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    display: grid;
    justify-content: stretch;
    grid-template-areas: 
    "leftArea       centerArea        addNew         addNew" 
    "leftArea       centerArea        categories     categories" 
    "leftArea       centerArea        delta    delta"
    "leftArea       bottomContainer   delta    delta";
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 250px auto auto auto;
}

/* TOP CROP CHILDREN CLASSES*/

.leftDivCropPage {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    background-color: #212121;
    grid-area: leftArea;
}

.centerCropPage {
    grid-area: centerArea;
    height: 100%;
    border-radius: 5px;
}

.rightDivCropPage {
    height: 100%;
    grid-area: rightArea;
}

/* BOTTOM CROP CHILDREN CLASSES*/

.rightTopCropContainer {
    display: flex;
}


.totalImagesCollectionDiv {
    display: flex;
    font-weight: 100;
    font-family: "Helvetica Neue";
    letter-spacing: 0.1em;
    color: #a9a9a9;
    font-size: 27px;
    position: relative;
    margin: auto 0 20px 10px;
}

.totalImagesCollectionDivNumber {
    color: crimson;
    margin-left: 8px;
    font-size: 30px;
    position: relative;
    bottom: 15px;
}

.buttonTutorialCrop {
    position: absolute !important;
    left: 40% !important;

}

.buttonTutorialCrop:hover {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.9);
}

.changeCollectionTDiv {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.changeCollectionTDiv div {
    font-family: "Helvetica Neue";
    font-weight: 400;
}

.changeCollectionTDiv img {
    max-width: 180px;

    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 1));

}

.imageSelect {
    border-radius: 5px;
    box-shadow: 0 0 3px 2px black;
}

.arrowsCrop {
    width: 75px !important;
    margin-left: 15px;
}


.freeCropT{
    max-width: 300px!important;
    filter: none!important;
    position: absolute;
    left: 20px;
    -webkit-filter: drop-shadow(0 0 9px rgba(0, 0, 0, 1)) !important;

}

.squareCropT{
    max-width: 100px!important;
    position: relative;
    bottom: 20px;
    right: 17px;
    border-radius: 5px;
    box-shadow: 0 0 3px 2px black;
}

.fixeDHeight{
    min-height: 200px!important;
}
.ulCategory{
    display: flex;
}
.ulCategory li{
    margin-top: 10px;
    margin-left: 25px;
    font-family: "Helvetica Neue";
    font-weight: bold;
    list-style-type: none;
}

.divStepsCategory{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.olCategory li{
    margin-top: 10px;
    margin-left: 30px;
    font-family: "Helvetica Neue";
    font-weight: 400;
}
.categoryRoles {
    position: absolute;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 0 0 3px 2px black;
    border-radius: 10px;
    right: 45px;
    bottom: 45px;
}
.imageRole{
    max-width: 100px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
    margin-top: 5px;

}

.boldCut{
    font-weight: bold;
}

.italicText{
    font-family: "Helvetica Neue";
    font-style: italic;
    font-weight: 400;
    margin:5px 0 20px 25px;
}

.marginAddTagsTutorial{
    /*margin-left: 10px;*/
    border-bottom: 1px solid crimson;
}

.dataSetTutorial{
    background-color: rgb(38, 38, 38);
    box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
    padding: 10px 15px 15px 15px;
    border-radius: 10px;
    margin-top: 15px;
}

.dataSetTutorialImages{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.dataSetTutorialImages img{
    width: 70px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
}
.dataSetTutorialImages div{
    font-weight: 500;
    font-family: "Helvetica Neue";
    font-size: 12px;
    position: relative;
    margin: 5px 0 2px 0;
    color: #de535f;
}

.titleTourDataSet{
    color: rgb(255, 157, 9);
    font-size: 20px;
}
.totalImageTut{
    margin-left: 10px;

}

.categoryActiveTour{
    max-width: 150px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
}
.tourTagging{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}
.currentCardTour{
    max-width: 100px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
}
.posTagging{
    position: relative;
    left: -5px;
    top: 50px !important;
}

.tourClickTagText{
    font-weight: 400;
    font-family: "Helvetica Neue";
}

.step_7_Crop{
    grid-area: delta;
    display: flex;
}

.currentCardTour2{
    max-width: 140px!important;
    margin-left: 25px;
    position: relative;
    bottom: 20px;
}

.clickCards{
    max-width: 100px;
}
.showTags{
    max-width: 450px;
    border-radius: 10px;
    box-shadow: 0 0 3px 2px black;
    margin-top: 20px;
}

.changeColl {
    max-width: 220px;
    max-height: 45px;
    position: relative;
    top: 5px;
}