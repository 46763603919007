.dataset {
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
}

.upperDivDataSet, .lowerDivDataSet {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}


.titleCentralNav {
    display: flex;
    color: #a9a9a9 !important;
    font-weight: 200 !important;
    align-items: flex-end;
    font-size: 20px;
    position: relative;
    bottom: 10px;
    margin-left: 5px;
    letter-spacing: 0.1em;
    font-family: "Helvetica Neue";
}

.titleCentralNav div {
    padding: 0 8px;
    font-weight: bold;
    position: relative;

}

.buttonTutorialDiv {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    transform: translate(-50%, -50%);
    height: 58px;
    display: flex;
    align-items: center;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.4);
    font-family: "Helvetica Neue";
    padding: 10px 20px 10px 25px;
    font-size: 41px;
    font-weight: 300;
    border-radius: 10px;
    color: crimson;
    /* box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.9); */
    letter-spacing: 0.3em;
    transition: 200ms;
    cursor: pointer;
}

.buttonTutorialDiv:hover {
    transition: 200ms;
    box-shadow: 0 2px 5px 3px rgba(220, 20, 60, 0.9);
    bottom: 5.5vh;
}

.descriptionStep{
    margin-bottom: 15px;
    font-family: "Helvetica Neue" !important;
}

.heightDescription{
    height: 360px;
}
.step_1_Main_Content img{
    width: 130px;
    height: 80px;
    box-shadow: 0 0 2px 1px black;
    border-radius: 5px;
}
.step_1_Main_cardsDiv{
    display: flex;
    justify-content: space-evenly;
}
.wrongChoice{
    position: relative;
}
.wrongChoice img{
    box-shadow: 0 0 3px 2px crimson!important;
}
.notRecommended{
    position: absolute;
    right: 0;
    top:-25px;
    color: crimson;
    font-weight: 300;
    font-family: "Helvetica Neue";
    font-size: 15px;
letter-spacing: 0.03em;
}
.Example{
    text-decoration: underline;
    color: #007aff;
}
.Example2{
    text-decoration: underline;
    color: #007aff;
    position: relative;
    top: 10px;
}


.step_2_Main_cardsDiv{
    display: flex;
    justify-content: space-evenly;
}


.step_2_label{
    height: 35px;
    font-family: "Helvetica Neue";
    position: relative;
    left: -10px;
}
.step_2_Main_category{
    position: relative;
}
.step_2_Main_category img{
    width: 170px;
    height: 150px;
    box-shadow: 0 0 2px 1px black;
    border-radius: 5px;
}

.step_2_Main_tag{
    position: relative;
    bottom: 23px;
}

.step_2_Main_tag img{
    width: 130px;
    height: 175px;
    box-shadow: 0 0 2px 1px black;
    border-radius: 5px;
}

