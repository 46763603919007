
.rightBar {
    background-color: #212121;
    width: 465px;
    height: 740px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px;
}

.imagesDivRight {
    width: 445px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    justify-content: flex-start;
    box-shadow: inset 0 0 5px rgb(0, 0, 0);
    max-height: 375px;
    overflow: auto;
    border-radius: 3px;
}

.imagesDivRight div {
    margin-top: 5px;
    padding: 2px;

}

.imagesDivRight div img {
    object-fit: contain;
    max-width: 140px;
    max-height: 80px;
    margin: 2px;
    border-radius: 3px;
    /*opacity: 0.65;*/
    box-shadow: 0 0 2px 1px black;
}

.imagesDivRight div img:hover {
    box-shadow: 0 0 4px 2px black;

}


.divIconRight {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212121;
    font-size: 50px;
    cursor: pointer;
    border-radius: 5px;
    padding: 0;
    width: fit-content;
    height: fit-content;
}

.divIconRight:hover {
    color: crimson;
    background-color: #212121;
    box-shadow: 0 0 4px 2px crimson;

}


.counterImageFolder {
    position: absolute;
    top: 0;
    right: 2px;
    color: #ffc109;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    margin: 3px;
}

.checkDelete {
    position: absolute;
    left: 16px;
    top: 10px;
    z-index: 10000;
    transform: translate(-50%, -50%);
}

.MuiButtonBase-root {
    padding: 0 !important;
    margin: 0 !important;
}

.divDeleteSelected {
    width: 99%;
    border-radius: 5px;
    margin: 10px auto;
    box-shadow: 0 0 2px 1px #ffc109;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoCounterDeleteDiv {
    margin: 0 0 0 10px;
    color: #a9a9a9;
    font-family: "Helvetica Neue";
}

.deleteSelectedButton {
    margin: 0 4px 0 0;
    height: 30px;;
    padding: 0 7px;
    box-shadow: 0 0 4px 2px black;
    color: crimson;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    letter-spacing: 0.03em;
    cursor: pointer;
}

.deleteSelectedButton:hover {
    box-shadow: 0 0 2px 2px crimson;
}

.deleteSelectedButton i {
    margin: 0 0 0 5px;
}

.iconWrapperRight {
    font-size: 35px;
    position: relative;
    bottom: 3px;
    transform: rotateX(40grad);

}

.iconWrapperRight svg {
    transform: rotateZ(-90deg);

}

.iconWrapperRight svg path {
    fill: #ffc109;
}

.navbar-toggle2 {
    color: #ffc109;
}

.goToCropButtonDiv {
    color: #212121;
    border-radius: 10px;
    display: flex;
    margin: 0 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center !important;
    font-family: "Helvetica Neue";
    font-weight: 300;
    letter-spacing: 0.1em;
    padding: 1px;
}

.goToCropButtonDiv:hover {
    background-color: #212121;
    color: crimson;
}

.iconGoToCrop {
    font-size: 50px !important;
    margin: 5px 5px 5px 6px;
}
