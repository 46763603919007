.navbar-container {
  display: flex;
  width: 100%;

}

.navbar-logo {
  color: rgba(220,20,60,0.9);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 3rem;
  display: flex;
  align-items: center;
  transition: 200ms;
  color: #212121;
  margin-left: 40px;

}

.navbar-logo:hover {
  text-decoration: none;
  transition: 200ms;
  color: rgba(220,20,60,1);

}

.fa-crop-alt {
  margin-left: 0.5rem;
  font-size: 2.8rem;
}

.nav-menu {
 display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-around;
  margin: 0;

}
.nav-item{
  display: flex;
  align-items: center;
  margin: 0 40px;
}

.nav-links {
  color: rgba(220,20,60,1);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Helvetica Neue" !important;
font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 22px;
  background-color: #212121;
  border-radius: 10px;
  transition: 200ms;
}

.navbar__link--active{
  /*border-bottom: 3px solid crimson;*/
  box-shadow: 0 2px 3px 2px crimson;
  transition: 200ms;
  color: #dc143c;
  position: relative;

  /*color: #ccff00;*/


}
.nav-links:hover {
  transform: translateY(-1px);
  text-decoration: none;
  color: crimson;
  /*color: #ccff00;*/
}

.bars {
  color: crimson;
}

.nav_links_mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 860px) {

  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem 0 2em 0;
    width: 100%;
    display: table;
    margin:auto;
  }
  .nav-links:hover {
    border-radius: 5px;
    width: 80%;
    margin:auto;

  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .times {
    color: crimson;
    font-size: 2rem;
  }
  .nav_links_mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #ccff00;
    padding: 14px 20px;
    border: 1px solid crimson;
    transition: all 0.3s ease-out;
  }
  .nav-links-mobile:hover {
    background: #ccff00;
    color: #242424;
    transition: 250ms;
  }
}

