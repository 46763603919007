.twitter-picker{
    background-color: unset!important;
    margin-right: 0!important;
}

.twitter-picker input {
    background-color: unset!important;
    height: 30px!important;
}

.twitter-picker> :nth-child(2) {
    opacity: 0;
}