:root {
    --blueText: #1e9dff;
    --darkGrey: #333333;
    --greyText: #606060
}

.accordionHead {
    /* */
    letter-spacing: 0.06em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #a9a9a9;
    font-size: 15px;
    font-family: "Helvetica Neue";
    font-weight: 300;
}

.accordionBody {
    width: 250px;
    display: flex;
    margin: auto;
    justify-content: center;
}

.MuiSvgIcon-root path {
    fill: #ccff00 !important;
}